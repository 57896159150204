<template>
    <div>
        <v-sheet class="dense-inputs">
            <v-overlay
                :value="loading.filters"
                absolute
                opacity="0.15"
            >
                <v-row>
                    <v-col class="text-center">
                        <v-progress-circular
                            color="primary"
                            indeterminate
                            size="40"
                            width="6"
                        ></v-progress-circular>
                    </v-col>
                </v-row>
            </v-overlay>
            <v-row no-gutters class="py-0">
                <v-col cols="6">
                    <div class="font-sm py-3">
                        <v-tooltip right>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    icon
                                    small
                                    v-bind="attrs"
                                    v-on="on"
                                    @click="addDataFilterDialog = true"
                                >
                                    <v-icon class="green--text darken-1">add</v-icon>
                                </v-btn>
                            </template>
                            <span>{{ $t('message.addDataFilter') }}</span>
                        </v-tooltip>
                    </div>
                    <v-data-table
                        :headers="headers"
                        :height="tableHeight"
                        :hide-default-header="hideDefaultHeader"
                        :hide-default-footer="hideDefaultFooter"
                        :items="DataFilters"
                        class="appic-table-light specification-table"
                        dense
                        disable-pagination
                        fixed-header
                        group-by="method"
                    >
                        <template v-slot:group.header="{ group }">
                            <td colspan="3" class="font-weight-bold">{{ group }}</td>
                        </template>
                        <template v-slot:item.filter="{ item }">
                            <span class="ml-3">{{ item.filter }}</span>
                        </template>
                        <template v-slot:item.value="{ item }">
                            <span class="ml-3">{{ item.value }}</span>
                        </template>
                        <template v-slot:item.id="{ item }">
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                    <v-btn icon @click="deleteDataFilter(item.index, item.id)" v-on="on" small>
                                        <v-icon color="grey darken-1" small>delete</v-icon>
                                    </v-btn>
                                </template>
                                <span>{{ $t('message.deleteThisItem') }}</span>
                            </v-tooltip>
                        </template>
                    </v-data-table>
                </v-col>
            </v-row>
        </v-sheet>
        <v-dialog
            persistent
            v-model="addDataFilterDialog"
            transition="dialog-bottom-transition"
            overlay=false
            scrollable
            width="575px"
            min-width="575px"
            max-width="600px"
        >
            <v-card>
                <v-card-title class="success white--text">
                    <span>{{ $t('message.addAbility') }}</span>
                </v-card-title>
                <v-card-text>
                    <v-form lazy-validation ref="dataFilterForm" v-model="validForm">
                        <v-layout row pt-3>
                            <v-flex xs6 lg3 md4 pl-5 py-1 class="align-center">{{ $t('message.methodFilter') }}</v-flex>
                            <v-flex xs6 lg9 md8 py-1>
                                <v-select
                                    :items="standardDataFilters"
                                    :rules="[...validationRules.required]"
                                    clearable
                                    dense
                                    hide-details="auto"
                                    solo
                                    v-model="newDataFilter"
                                    @change="dataFilterSelected($event)"
                                ></v-select>
                            </v-flex>
                        </v-layout>
                        <v-layout row v-if="newDataFilter != null">
                            <v-flex xs6 lg3 md4 pl-5 py-1 class="align-center">{{ $t('message.availableValues') }}</v-flex>
                            <v-flex xs6 lg9 md8 py-1>
                                <v-autocomplete
                                    :items="filterValues"
                                    :loading="loading.values"
                                    :rules="[...validationRules.required]"
                                    clearable
                                    dense
                                    hide-details="auto"
                                    solo
                                    v-model="newDataValue"
                                ></v-autocomplete>
                            </v-flex>
                        </v-layout>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="default" small @click="addDataFilterDialog = false" >{{$t('message.dismiss')}}</v-btn>
                    <v-btn
                        :loading="loading.add"
                        color="info"
                        small
                        @click="addUserDataFilter()"
                    >{{ $t('message.save') }}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import {formatDate} from "Helpers/helpers";
import {api} from 'Api';
import {mapGetters} from "vuex";

export default {
    name: "DataFiltersListing",
    props: ['userId'],
    data() {
        return {
            addDataFilterDialog: false,
            DataFilters: [],
            dialogs: {
                error:  false,
                error_message: ""
            },
            filterValues: [],
            hideDefaultHeader: false,
            hideDefaultFooter: true,
            loading: {
                add: false,
                filters: false,
                values: false
            },
            newDataFilter: null,
            newDataValue: null,
            rules: {
                newDataFilter: v => !!v || this.$t('message.required'),
                newDataValue: v => !!v || this.$t('message.required'),
            },
            standardDataFilters: [],
            tableHeight: 300,
            validForm: false
        }
    },
    computed: {
        ...mapGetters([
            'validationRules'
        ]),
        formatDate: () => formatDate,
        headers() {
            return [
                {
                    id: 0,
                    text: this.$t('message.method'),
                    value: 'method',
                    class: 'light-green lighten-3 py-0 pr-0 pl-4 font-sm text-left',
                    sortable: false,
                    searchable: false
                },
                {
                    id: 1,
                    text: this.$t('message.methodFilter'),
                    value: 'filter',
                    class: 'light-green lighten-3 py-0 pr-0 pl-4 font-sm text-left',
                    sortable: false,
                    searchable: false
                },
                {
                    id: 2,
                    text: this.$t('message.value'),
                    value: 'value',
                    class: 'light-green lighten-3 py-0 pr-0 pl-4 font-sm text-left',
                    sortable: false,
                    searchable: false
                },
                {
                    id: 3,
                    text: this.$t('message.actions'),
                    value: 'id',
                    class: 'light-green lighten-3 py-0 pr-4 pl-0 font-sm text-left width-1-pct',
                    sortable: false,
                    searchable: false
                }
            ]
        }
    },
    methods: {
        addDataFilter() {
            return new Promise((resolve, reject) => {
                api
                    .post('/users/' + this.userId + '/data-filters', {
                        filter: {
                            condition_id: this.newDataFilter,
                            value: this.newDataValue,
                            status: 1
                        }
                    })
                    .then((response) => {
                        if (response.data.status == 'success') {
                            resolve('done')
                        } else {
                            resolve('error')
                        }
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        },
        addUserDataFilter() {
            let valid = this.$refs.dataFilterForm.validate()
            if(valid){
                this.loading.add = true
                this.addDataFilter().then(() => {
                    this.loading.add = false
                    this.loading.filters = true
                    this.addDataFilterDialog = false
                    this.getUserDataFilters().then(() => {
                        this.loading.filters = false
                    })
                })
            }
        },
        dataFilterSelected(filterId) {
            if(filterId != null){
                this.loading.values = true
                api.get('/users/' + this.userId + '/data-filters/' + filterId + '/values')
                    .then((response) => {
                        this.filterValues = response.data.data
                        this.loading.values = false
                    })
                    .catch(()=>{
                        this.loading.values = false
                    })
            }
        },
        async deleteDataFilter(itemIndex, itemId) {
            if (await this.$root.$confirm(this.$t('message.delete'), this.$t('message.confirmations.continueDeleteItemAction'), {color: 'red'})) {
                let promise = new Promise((resolve, reject) => {
                    this.DataFilters.splice(itemIndex, 1)
                    let i = 0;
                    this.DataFilters.forEach((item) => {
                        item.index = i
                        i++
                    })
                    resolve('done')
                })
                promise.then(() => {
                    if (itemId != null) {
                        api
                            .delete('/users/' + this.userId + '/data-filters/' + itemId)
                            .then((response) => {
                                if(response.data.status == 'success') {
                                    this.$toast.success(this.$t('message.successes.dataFilterDeleted'),
                                        {
                                            classes: ['icon-float-left'],
                                            icon: 'check_circle_outline'
                                        }
                                    )
                                } else {
                                    this.$toast.error(this.$t('message.errors.dataFilterNotDeleted'),
                                        {
                                            classes: ['icon-float-left'],
                                            icon: 'error_outline'
                                        }
                                    )
                                }
                            })
                            .catch(() => {
                                this.$toast.error(this.$t('message.errors.dataFilterNotDeleted'),
                                    {
                                        classes: ['icon-float-left'],
                                        icon: 'error_outline'
                                    }
                                )
                            })
                    }
                })
            }
        },
        getStandardDataFilters(){
            new Promise((resolve, reject) => {
                api
                    .get('/users/' + this.userId + '/standard-data-filters')
                    .then(response => {
                        this.standardDataFilters = response.data.data
                        this.standardDataFilters.sort((a, b)=>{
                            let strA = a['text'].toUpperCase()
                            let strB = b['text'].toUpperCase()
                            return strA.localeCompare(strB)
                        })
                        resolve('done')
                    })
                    .catch(error => {
                        reject(error);
                    });
            })
        },
        getUserDataFilters() {
             return new Promise((resolve, reject) => {
                api
                    .get('/users/' + this.userId + '/data-filters')
                    .then(response => {
                        let i = 0
                        this.DataFilters = response.data.data
                        this.DataFilters.forEach((item) => {
                            item.idx = i
                            i++
                        })
                        resolve('done')
                    })
                    .catch(error => {
                        reject(error);
                    });
            })
        }
    },
    created(){
        this.loading.filters = true
        this.getUserDataFilters()
            .then(()=>{
                this.getStandardDataFilters()
                    .then(() => {
                        this.loading.filters = false
                    })
            })
            .catch(()=>{
                this.loading.filters = false
            })
    }
}
</script>

<style scoped>

</style>