<template>
    <div>
        <v-sheet class="dense-inputs">
            <v-overlay
                :value="loading.permissions"
                absolute
                opacity="0.15"
            >
                <v-row>
                    <v-col class="text-center">
                        <v-progress-circular
                            color="primary"
                            indeterminate
                            size="40"
                            width="6"
                        ></v-progress-circular>
                    </v-col>
                </v-row>
            </v-overlay>
            <div class="pt-3">{{ $t('message.userRole') + ': '}}<span class="font-weight-bold">{{ userRoles.find(r => r.value == User__role).text }}</span></div>
            <v-row no-gutters class="pt-3">
                <v-col cols="6">
                    <div class="font-sm pb-2">{{ $t('message.defaultPermissions') }}</div>
                    <v-data-table
                        :headers="headers.default"
                        :height="tableHeight"
                        :hide-default-header="hideDefaultHeader"
                        :hide-default-footer="hideDefaultFooter"
                        :items="DefaultAccessPermissions"
                        calculate-widths
                        class="appic-table-light specification-table mr-3"
                        dense
                        disable-pagination
                        fixed-header
                        group-by="subject"
                        item-key="item.index"
                    >
                        <template v-slot:group.header="{ group }">
                            <td colspan="2" class="font-weight-bold">{{ group }}</td>
                        </template>
                        <template v-slot:item.action="{ item }">
                            <span class="ml-3">{{ item.action }}</span>
                        </template>
                        <template v-slot:item.access="{ item }">
                            <v-switch
                                :false-value="0"
                                :input-value="item.access"
                                :label="item.access == 1 ? $t('message.yes') : $t('message.no')"
                                :true-value="1"
                                :value="item.access"
                                class="mt-0"
                                dense
                                hide-details="auto"
                                flat
                                @change="updateUserAccess('default',item.idx, item.index, $event)"
                            ></v-switch>
                        </template>
                    </v-data-table>
                </v-col>
                <v-col cols="6">
                    <div class="font-sm">
                        {{ $t('message.customPermissions') }}
                        <v-tooltip right>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    icon
                                    small
                                    v-bind="attrs"
                                    v-on="on"
                                    @click="addAccessPermissionDialog = true"
                                >
                                    <v-icon class="green--text darken-1">add</v-icon>
                                </v-btn>
                            </template>
                            <span>{{ $t('message.addPermission') }}</span>
                        </v-tooltip>
                    </div>
                    <v-data-table
                        :headers="headers.custom"
                        :height="tableHeight"
                        :hide-default-header="hideDefaultHeader"
                        :hide-default-footer="hideDefaultFooter"
                        :items="CustomAccessPermissions"
                        calculate-widths
                        class="appic-table-light specification-table"
                        dense
                        disable-pagination
                        fixed-header
                        group-by="subject"
                        item-key="item.index"
                    >
                        <template v-slot:group.header="{ group }">
                            <td colspan="3" class="font-weight-bold">{{ group }}</td>
                        </template>
                        <template v-slot:item.action="{ item }">
                            <span class="ml-3">{{ item.action }}</span>
                        </template>
                        <template v-slot:item.access="{ item }">
                            <v-switch
                                :false-value="0"
                                :input-value="item.access"
                                :label="item.access == 1 ? $t('message.yes') : $t('message.no')"
                                :true-value="1"
                                :value="item.access"
                                class="mt-0"
                                dense
                                hide-details="auto"
                                flat
                                @change="updateUserAccess('custom',item.idx, item.index, $event)"
                            ></v-switch>
                        </template>
                        <template v-slot:item.id="{ item }">
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                    <v-btn icon @click="deleteCustomAccessPermission(item.index, item.id)" v-on="on" small>
                                        <v-icon color="grey darken-1" small>delete</v-icon>
                                    </v-btn>
                                </template>
                                <span>{{ $t('message.deleteThisItem') }}</span>
                            </v-tooltip>
                        </template>
                    </v-data-table>
                </v-col>
            </v-row>
        </v-sheet>
        <v-dialog
            persistent
            v-model="addAccessPermissionDialog"
            transition="dialog-bottom-transition"
            overlay=false
            scrollable
            max-width="400px !important"
        >
            <v-card>
                <v-card-title class="success white--text">
                    <span>{{ $t('message.addAbility') }}</span>
                </v-card-title>
                <v-card-text>
                    <v-form lazy-validation ref="accessPermissionForm" v-model="validForm">
                        <v-layout row pt-3>
                            <v-flex xs6 lg4 md5 pl-5 py-1 class="align-center">{{ $t('message.ability') }}</v-flex>
                            <v-flex xs6 lg8 md7 py-1>
                                <v-select
                                    :items="standardAbilities"
                                    :rules="[rules.newAbility]"
                                    dense
                                    hide-details="auto"
                                    solo
                                    style="width: 300px"
                                    v-model="newAbility"
                                ></v-select>
                            </v-flex>
                        </v-layout>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="default" small @click="addAccessPermissionDialog = false" >{{$t('message.dismiss')}}</v-btn>
                    <v-btn
                        :loading="loading.add"
                        color="info"
                        small
                        @click="addUserAccess()"
                    >{{ $t('message.save') }}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import {formatDate} from "Helpers/helpers";
import {api} from 'Api';
import {mapFields} from "vuex-map-fields";
import {mapGetters} from "vuex";

export default {
    name: "AccessPermissionManager",
    props: ['userId'],
    data() {
        return {
            addAccessPermissionDialog: false,
            CustomAccessPermissions: [],
            DefaultAccessPermissions: [],
            dialogs: {
                error:  false,
                error_message: ""
            },
            hideDefaultHeader: false,
            hideDefaultFooter: true,
            loading: {
                add: false,
                permissions: false
            },
            newAbility: null,
            rules: {
                newAbility: v => !!v || this.$t('message.required'),
            },
            standardAbilities: [],
            tableHeight: 300,
            validForm: false
        }
    },
    computed: {
        ...mapFields('user',{
            User__role: 'update.User.role'
        }),
        ...mapGetters([
            'userRoles'
        ]),
        formatDate: () => formatDate,
        headers() {
            return {
                custom: [
                    {
                        id: 0,
                        text: this.$t('message.subject'),
                        value: 'subject',
                        class: 'light-green lighten-3 py-0 pr-0 pl-4 font-sm text-left',
                        searchable: false,
                        sortable: false
                    },
                    {
                        id: 1,
                        text: this.$t('message.subjectAction'),
                        value: 'action',
                        class: 'light-green lighten-3 py-0 pr-0 pl-4 font-sm text-left',
                        searchable: false,
                        sortable: false
                    },
                    {
                        id: 2,
                        text: this.$t('message.access'),
                        value: 'access',
                        class: 'light-green lighten-3 py-0 pr-0 font-sm text-left',
                        searchable: false,
                        sortable: false
                    },
                    {
                        id: 3,
                        text: this.$t('message.actions'),
                        value: 'id',
                        class: 'light-green lighten-3 py-0 pr-4 pl-0 font-sm text-left width-1-pct',
                        searchable: false,
                        sortable: false
                    },
                ],
                default: [
                    {
                        id: 0,
                        text: this.$t('message.subject'),
                        value: 'subject',
                        class: 'light-green lighten-3 py-0 pr-0 pl-4 font-sm text-left',
                        searchable: false,
                        sortable: false
                    },
                    {
                        id: 1,
                        text: this.$t('message.subjectAction'),
                        value: 'action',
                        class: 'light-green lighten-3 py-0 pr-0 pl-4 font-sm text-left',
                        searchable: false,
                        sortable: false
                    },
                    {
                        id: 2,
                        text: this.$t('message.access'),
                        value: 'access',
                        class: 'light-green lighten-3 py-0 pr-0 font-sm text-left',
                        searchable: false,
                        sortable: false
                    }
                ]
            }
        }
    },
    methods: {
        addAccessPermission() {
            return new Promise((resolve, reject) => {
                api
                    .post('/users/' + this.userId + '/role-ability', {
                        ability: {
                            ability: this.newAbility,
                            has_access: 1,
                            custom: 1
                        }
                    })
                    .then((response) => {
                        if (response.data.status == 'success') {
                            resolve('done')
                        } else {
                            resolve('error')
                        }
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        },
        addUserAccess() {
            let valid = this.$refs.accessPermissionForm.validate()
            if(valid){
                this.loading.add = true
                this.addAccessPermission().then(() => {
                    this.loading.add = false
                    this.loading.permissions = true
                    this.addAccessPermissionDialog = false
                    this.getUserAccessPermissions().then(() => {
                        this.loading.permissions = false
                    })
                })
            }
        },
        async deleteCustomAccessPermission(itemIndex, itemId) {
            if (await this.$root.$confirm(this.$t('message.delete'), this.$t('message.confirmations.continueDeleteItemAction'), {color: 'red'})) {
                let promise = new Promise ((resolve, reject) => {
                    this.CustomAccessPermissions.splice(itemIndex, 1)
                    let i = 0;
                    this.CustomAccessPermissions.forEach((item) => {
                        item.index = i
                        i++
                    })
                    resolve('done')
                })
                promise.then(() => {
                    if(itemId != null){
                        api
                            .delete('/users/' + this.userId + '/role-ability/' + itemId,)
                            .then(() => {
                                this.$toast.success(this.$t('message.successes.accessDeleted'),
                                    {
                                        classes: ['icon-float-left'],
                                        icon: 'check_circle_outline'
                                    }
                                )
                            })
                            .catch(() => {
                                this.$toast.error(this.$t('message.errors.accessNotDeleted'),
                                    {
                                        classes: ['icon-float-left'],
                                        icon: 'error_outline'
                                    }
                                )
                            })
                    }
                })
            }
        },
        getStandardAccessPermissions(){
            new Promise((resolve, reject) => {
                api
                    .get('/users/' + this.userId + '/standard-role-ability')
                    .then(response => {
                        let defaultAbilities = []
                        this.DefaultAccessPermissions.forEach(p => {
                            defaultAbilities.push(p.index)
                        })
                        let abilities = response.data.data
                        abilities.forEach( ability => {
                            if(!defaultAbilities.includes(ability.value)) this.standardAbilities.push(ability)
                        })
                        resolve('done')
                    })
                    .catch(error => {
                        reject(error);
                    });
            })
        },
        getUserAccessPermissions() {
            return new Promise((resolve, reject) => {
                api
                    .get('/users/' + this.userId + '/role-ability')
                    .then(response => {
                        let i = 0
                        this.CustomAccessPermissions = response.data.data.custom
                        this.CustomAccessPermissions.forEach((item) => {
                            item.idx = i
                            i++
                        })
                        i = 0
                        this.DefaultAccessPermissions = response.data.data.default
                        this.DefaultAccessPermissions.forEach((item) => {
                            item.idx = i
                            i++
                        })
                        resolve('done')
                    })
                    .catch(error => {
                        reject(error);
                    });
            })
        },
        updateUserAccess(list, idx, ability, value) {
            if(list == 'default'){
                this.DefaultAccessPermissions[idx]['access'] = value
                this.updateAccess({
                        ability: ability,
                        has_access: value
                    })
                    .then(() => {
                        this.$toast.success(this.$t('message.successes.accessUpdated'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'check_circle_outline'
                            }
                        )
                    })
                    .catch(() => {
                        this.$toast.error(this.$t('message.errors.accessNotUpdated'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                    })
            } else if(list == 'custom'){
                this.CustomAccessPermissions[idx]['access'] = value
                this.updateCustomAccess({
                    ability: ability,
                    has_access: value
                })
                    .then(() => {
                        this.$toast.success(this.$t('message.successes.accessUpdated'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'check_circle_outline'
                            }
                        )
                    })
                    .catch(() => {
                        this.$toast.error(this.$t('message.errors.accessNotUpdated'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                    })
            }
        },
        updateAccess(ability) {
            return new Promise((resolve, reject) => {
                api
                    .put('/users/' + this.userId + '/role-ability',{
                        ability: {
                            ability: ability.ability,
                            has_access: ability.has_access
                        }
                    })
                    .then((response) => {
                        if(response.data.status == 'success') {
                            resolve('done')
                        } else {
                            resolve('error')
                        }
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        },
        updateCustomAccess(ability) {
            return new Promise((resolve, reject) => {
                api
                    .put('/users/' + this.userId + '/custom-role-ability',{
                        ability: {
                            ability: ability.ability,
                            has_access: ability.has_access
                        }
                    })
                    .then((response) => {
                        if(response.data.status == 'success') {
                            resolve('done')
                        } else {
                            resolve('error')
                        }
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },
    created(){
        this.loading.permissions = true
        this.getUserAccessPermissions()
            .then(()=>{
                this.getStandardAccessPermissions()
                    .then(() => {
                        this.loading.permissions = false
                    })
            })
            .catch(()=>{
                this.loading.permissions = false
            })
    }
}
</script>

<style scoped>

</style>